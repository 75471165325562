import { useState, useEffect } from "react";
import styled from "styled-components";
import { EnvVars } from "./env";
import Hero from "./views/TavernPage/Hero";
import Menu from "./views/TavernPage/Menu";
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import MenuFooter from "./components/Menu/Footer";
import Container from "./components/Container";

export default function TavernDetailsPage() {
  const { tavernId } = useParams();

  const [tavern, setTavern] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true);

    if (!tavernId) {
      return;
    }

    window.location.hash = "";

    fetch(EnvVars.API + "restaurant/" + tavernId)
      .then((res) => res.json())
      .then((data) => {
        let categories = data.data.categories;
        categories.sort((a, b) => a.order > b.order ? 1 : (a.order < b.order ? -1 : 0));
        categories.forEach(x => {
          x.dishes.sort((a, b) => a.order > b.order ? 1 : (a.order < b.order ? -1 : 0));
        });

        // console.log(data.data);
        setTavern(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
    }, [tavernId])

    return (
      <HomepageWrapper>
        <Helmet>
          <title>{ (isLoading ? '' : tavern.name + ' – ') + EnvVars.SITE_NAME }</title>
        </Helmet>

        <WhiteBackgroundContainer>
          { isLoading 
            ?
              <></>
            :
              <>
                {tavern.blocked !== 0 && 
                  <Container>
                    <ErrorNotificationContainer>QR меню не оплачено. Сообщите администратору заведения, что требуется внести оплату.</ErrorNotificationContainer>
                  </Container>
                }

                <Hero title={ tavern.name } image={ EnvVars.SOURCE + tavern.bgImage }/>

                {tavern.blocked === 0 && 
                  <Menu categories={ 
                    tavern.categories.map(x => {
                      return {
                        title: x.name,
                        items: x.dishes.map(d => {
                          return {
                            title: d.name,
                            description: d.description,
                            price: d.price,
                            image: d.image ? EnvVars.SOURCE + d.image : '/menu/empty-dish.svg'
                          }
                        })
                      }
                    })
                  }/>
                }
              </>
          }
        </WhiteBackgroundContainer>
        <MenuFooter />
      </HomepageWrapper>
    );
}

const HomepageWrapper = styled.div`
  
`;

const WhiteBackgroundContainer = styled.div`
  
`;

const ElementContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.02);
  padding: 40px;
  font-size: 18px;

  @media (max-width: 1024px) {
    padding: 30px 20px;
  }

  @media (max-width: 576px) {
    padding: 20px 15px;
  }
`;

const NotificationContainer = styled(ElementContainer)`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${({ $isError }) => $isError ? '#EF5350' : '#66BB6A'};
  color: white;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const ErrorNotificationContainer = styled(NotificationContainer)`
  background-color: #EF5350;
`